import React from "react"
import classNames from "classnames"

import FeaturesGallery from "../../../components/_V2/FeaturesGallery"

import styles from "./styles.module.scss"

import data from "./data"

interface Props {
	className?: string
}

export default function StockFeatures(props: Props) {
	return (
		<section className={classNames(styles.section, props.className)}>
			<FeaturesGallery
				className={styles.featureGallery}
				theme={"purple-custom-backdrop"}
				features={data.features}
			/>
		</section>
	)
}
