import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg"

import mediaContent from "./mediaContent.module.scss"
import backdropIconsStyles from "./backdropIconsStyles.module.scss"

export default {
	features: [
		{
			desktopContentWidth: 500,
			tabName: "Яндекс Доставка",
			desktopTitle: "Ресторанный \nэкспресс",
			desktopContent: (
				<p className={mediaContent.description}>
					Привлекай дополнительных курьеров, если не&nbsp;хватает своих.
					Незаменимый сервис в&nbsp;пиковые часы.
				</p>
			),
			mediaContent: (
				<div className={mediaContent.images__container}>
					<StaticImage
						className={mediaContent.image}
						src="./assets/image1.png"
						alt={"интеграция с яндекс доставкой"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</div>
			),
			backdropContent: <div className={backdropIconsStyles.backdrop} />,
		},
		{
			desktopContentWidth: 500,
			tabName: "Супер быстро",
			desktopTitle: "2 минуты",
			desktopContent: (
				<p className={mediaContent.description}>
					Столько времени в&nbsp;среднем занимает поиск курьера. Еще через
					10&nbsp;минут он&nbsp;приедет, чтобы забрать заказ.
				</p>
			),
			mediaContent: (
				<div className={mediaContent.images__container}>
					<StaticImage
						className={mediaContent.image}
						src="./assets/image2.png"
						alt={"поиск курьера на доставку"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</div>
			),
			backdropContent: <div className={backdropIconsStyles.backdrop} />,
		},
		{
			desktopContentWidth: 500,
			tabName: "Трекинг",
			desktopTitle: "Всё отмечено \nна карте",
			tabletTitle: "Всё отмечено на карте",
			desktopContent: (
				<p className={mediaContent.description}>
					Клиенты могут отслеживать заказы из&nbsp;ресторана в&nbsp;приложении
					Яндекс&nbsp;Go.
				</p>
			),
			mediaContent: (
				<div className={mediaContent.images__container}>
					<StaticImage
						className={mediaContent.imagePhone}
						src="./assets/image3.png"
						alt={"отслеживание заказа из ресторана"}
						objectFit={"contain"}
						placeholder={"blurred"}
						quality={90}
					/>
				</div>
			),
			backdropContent: <div className={backdropIconsStyles.backdrop} />,
		},
	],
} as {
	features: Array<FeatureGalleryItem>
}
