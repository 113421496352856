import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"

// @ts-ignore
import ChevronRight from "../../../assets/svg/chevronRight.svg"

import mediaContent from "./mediaContent.module.scss"
import styles from "./styles.module.scss"

export default {
	features: [
		{
			desktopContentWidth: 481,
			tabName: "Стоп-листы",
			desktopTitle: "Продавай то,\nчто есть",
			mobileTitle: "Продавай то,\nчто есть",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Управляй доступными позициями с&nbsp;помощью стоп-листов. Гости будут
					видеть только&nbsp;то, что есть в&nbsp;наличии.
				</p>
			),
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/yandex-features-1.png"
					alt={"списание продуктов в кафе"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
			backdropContent: <div className={styles.backdrop} />,
		},
		{
			desktopContentWidth: 481,
			tabName: "Синхронизация",
			desktopTitle: "Всегда актуальное меню",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Меню обновляется автоматически в&nbsp;приложении и&nbsp;на&nbsp;сайте
					агрегатора.
				</p>
			),
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/yandex-features-2.png"
					alt={"акт переработки"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
			backdropContent: <div className={styles.backdrop} />,
		},
	],
} as {
	features: Array<FeatureGalleryItem>
}
