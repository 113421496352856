import React from "react"
import classNames from "classnames"

import DefaultLayout from "../layout/default"

import pageStyles from "../styles/_V2/pagesStyles/styles.module.scss"
import styles from "./styles/_V2/yandex.module.scss"
import YandexPresentation from "../pagesSections/yandex/ProductPresentation"
import Advantages from "../components/_V2/Advantages"
import CRMFeatures from "../pagesSections/yandex/CRMFeatures"
import AccountingFeatures from "../pagesSections/yandex/AccountingFeatures"
import DeliveryFeatures from "../pagesSections/yandex/DeliveryFeatures"
import AdditionalServices from "../pagesSections/index/AdditionalServices"
import CheckFeatures from "../pagesSections/yandex/CheckFeatures"
import CtaForm from "../pagesSections/index/CtaForm"
import BusinessTypes from "../components/_V2/BusinessTypes"
import KnowledgeBase from "../pagesSections/index/KnowledgeBase"
import Presentation from "../pagesSections/yandex/ProductPresentation/assets/yandex-presentation.png"

import yandexAdvantages from "../pages-data/_V2/yandex/advantages"

import { businessTypes } from "../pages-data/_V2/common/business-types-compact"
import { useLocalizationContext } from "../localization/useLocalizationContext";

export default function YandexPage() {
	const localizationContext = useLocalizationContext();

	const metaTags = [
		{
			name: "description",
			content:
				"Готовый канал продаж ✔ Интеграция расширяет зону доставки, открывает доступ к аудитории агрегатора ✔ Все заказы в одной системе ✔ Выбирай, кто будет доставлять заказы — курьеры сервиса-агрегатора или свои сотрудники ✔ Оплачивай заказ по QR-коду → Меню и акции обновляются автоматически в приложении и на сайте агрегатора.",
		},
		// {
		//   name: 'keywords',
		//   content: 'программа, лояльность, гости, мобильное приложение, предзаказ, пуш-уведомление, автоматизация, кафе, ресторан',
		// },
		{
			property: "og:url",
			content: "https://quickresto.ru/yandex/",
		},
		{
			property: "og:title",
			content: "Интеграция Quick Resto и Delivery Club",
		},
		{
			property: "og:description",
			content:
				"Готовый канал продаж ✔ Интеграция расширяет зону доставки, открывает доступ к аудитории агрегатора ✔ Все заказы в одной системе ✔ Выбирай, кто будет доставлять заказы — курьеры сервиса-агрегатора или свои сотрудники → Меню и акции обновляются автоматически в приложении и на сайте агрегатора.",
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content: `https://quickresto.ru${Presentation}`,
		},
	]

	return (
		<DefaultLayout
			title="Интеграция Quick Resto и сервисов Яндекса: Еда, Доставка, Чек"
			metaTags={metaTags}
			mainWrapperClassName={pageStyles.pageWrapper}
			linkCanonical="https://quickresto.ru/yandex/"
			footerClassName={pageStyles.pageSection}
		>
			<YandexPresentation />

			<Advantages
				items={yandexAdvantages}
				className={classNames(
					styles.yandex__advantages,
					pageStyles.pageSection
				)}
			/>

			<CRMFeatures className={pageStyles.pageSection} />

			<AccountingFeatures className={pageStyles.pageSection} />

			<DeliveryFeatures className={pageStyles.pageSection} />

			<CheckFeatures className={pageStyles.pageSection} />

			<AdditionalServices />

			<CtaForm isFry={false} />

			<BusinessTypes
				key={localizationContext.locale}
				data={businessTypes(localizationContext.locale)}
				className={classNames(pageStyles.pageSection, styles.yandex__services)}
			/>

			<KnowledgeBase type="horizontal" className={pageStyles.pageSection} />
		</DefaultLayout>
	)
}
