import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import styles from "./styles.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Чек",
			desktopTitle: <p>Быстрая оплата с&nbsp;сервисом Чек</p>,
			desktopContent: (
				<p>
					Оплата и&nbsp;чаевые по&nbsp;единому QR-коду. Теперь гостю
					не&nbsp;нужно ждать, когда официант принесет счет.
				</p>
			),
			detailLink: "#popup",
			detailText: "Оставить заявку",
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/check-features-1.png"
					alt="оплата счета яндекс чек"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			backdropContent: <div className={styles.backdrop} />,
		},
		{
			desktopContentWidth: 490,
			tabName: "Как это работает",
			desktopTitle: "Гость сам ускоряет работу заведения",
			desktopContent: (
				<p>
					Теперь он&nbsp;не&nbsp;ждет, когда ему вынесут меню, а&nbsp;сканирует
					его с&nbsp;QR-кода. Выбирает блюдо и&nbsp;делает заказ
					у&nbsp;официанта. Не&nbsp;ждет чек, а&nbsp;сам расплачивается через
					приложение по&nbsp;СБП или Yandex Pay.
				</p>
			),
			detailLink: "#popup",
			detailText: "Оставить заявку",
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/check-features-2.png"
					alt="оплата счета по qr-коду"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			backdropContent: <div className={styles.backdrop} />,
		},
		{
			desktopContentWidth: 490,
			tabName: "Выгоды",
			desktopTitle: (
				<>
					Оборот столов
					<br />
					на&nbsp;10% выше
				</>
			),
			desktopContent: (
				<p>
					Сервис экономит деньги и&nbsp;время: закрытие стола происходит
					за&nbsp;7&nbsp;секунд, ставка сервиса ниже, чем при
					эквайринге&nbsp;&mdash; всего 1,3% с&nbsp;транзакции.
				</p>
			),
			detailLink: "#popup",
			detailText: "Оставить заявку",
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/check-features-3.png"
					alt="способы оплаты счета в ресторане"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
			backdropContent: <div className={styles.backdrop} />,
		},
	],
}
