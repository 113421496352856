import React from "react"

export default [
	{
		title: "Быстрый старт",
		description:
			"Интеграция с&nbsp;основными сервисами для бизнеса на&nbsp;тарифах Standard и&nbsp;Pro.",
	},
	{
		title: "Без разработчиков",
		description:
			"Простое подключение на&nbsp;&laquo;раз-два&raquo;. Но&nbsp;если потребуется, то&nbsp;служба поддержки будет рядом.",
	},
	{
		title: "Готовый канал продаж",
		description:
			"Размести меню и&nbsp;акции в&nbsp;агрегаторе бесплатно. Комиссия начисляется только с&nbsp;заказов.",
	},
]
