import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	pageName: "Интеграция с сервисами Яндекса",
	desktopTitle: "Удобное партнёрство",
	subTitle: (
		<p className={styles.description}>
			Заказ еды с&nbsp;доставкой, курьерская служба и&nbsp;быстрая оплата заказа
			по&nbsp;QR-коду.
		</p>
	),
	cta: pagesLinks.registration.text,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
	ctaLink: pagesLinks.registration,
	figure: (
		<div className={styles.image}>
			<StaticImage
				alt="интеграция с сервисами яндекса"
				src="./assets/yandex-presentation.png"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		</div>
	),
}
