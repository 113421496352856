import React from "react"
import { SolidTabsProps } from "../../../components/_V2/SolidTabs"
import styles from "./styles.module.scss"
import { StaticImage } from "gatsby-plugin-image"
import classNames from "classnames"

const data: SolidTabsProps = {
	items: [
		{
			tabName: "Яндекс Еда",
			desktopTitle: "+25% к прибыли",
			mobileTitle: "+25% к прибыли",
			tabletTitle: "+25% к прибыли",
			desktopContent: (
				<>
					Постоянный поток клиентов из&nbsp;агрегатора, рост заказов
					от&nbsp;постоянных гостей.
					<br />
					Теперь их&nbsp;так легко порадовать любимыми блюдами с&nbsp;доставкой
					до&nbsp;дома.
				</>
			),
			tabletContent: (
				<>
					Постоянный поток клиентов из&nbsp;агрегатора, рост заказов
					от&nbsp;постоянных гостей. Теперь их&nbsp;так легко порадовать
					любимыми блюдами с&nbsp;доставкой до&nbsp;дома.
				</>
			),
			mobileContent: (
				<>
					Постоянный поток клиентов из&nbsp;агрегатора, рост заказов
					от&nbsp;постоянных гостей.
					<br />
					Теперь их&nbsp;так легко порадовать любимыми блюдами с&nbsp;доставкой
					до&nbsp;дома.
				</>
			),
			mediaContent: (
				<div className={styles.mediaContainer}>
					<StaticImage
						className={classNames(styles.mediaImage, styles.mediaImage__first)}
						src={"./assets/yandex-image-1.png"}
						alt={"заказы на доставку из ресторана"}
						objectFit="contain"
						breakpoints={[223, 642, 690]}
						quality={90}
					/>
				</div>
			),
			backdropContent: <div className={styles.backdrop}></div>,
		},
		{
			tabName: "Заказы",
			desktopTitle: "Все заказы в одной системе",
			mobileTitle: "Все заказы в одной системе",
			tabletTitle: "Все заказы в одной системе",
			desktopContent: (
				<>
					Принимай все заказы на&nbsp;кассовом терминале Quick Resto.
					<br />
					Не&nbsp;нужно использовать второй планшет для Яндекс Еды.
				</>
			),
			tabletContent: (
				<>
					Принимай все заказы на&nbsp;кассовом терминале Quick Resto.
					Не&nbsp;нужно использовать второй планшет для Яндекс Еды.
				</>
			),
			mobileContent: (
				<>
					Принимай все заказы на&nbsp;кассовом терминале Quick Resto.
					<br />
					Не&nbsp;нужно использовать второй планшет для Яндекс Еды.
				</>
			),
			mediaContent: (
				<div className={styles.mediaContainer}>
					<StaticImage
						className={classNames(styles.mediaImage, styles.mediaImage__second)}
						src={"./assets/yandex-image-2.png"}
						alt={"доставка курьерской службой"}
						objectFit="contain"
						breakpoints={[223, 642, 690]}
						quality={90}
					/>
				</div>
			),
			backdropContent: <div className={styles.backdrop}></div>,
		},
		{
			tabName: "Авторизация гостя",
			desktopTitle: "Гостю приятно, когда его узнают",
			mobileTitle: "Гостю приятно, когда его узнают",
			tabletTitle: "Гостю приятно, когда его узнают",
			desktopContent: (
				<>
					Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки
					отобразится на&nbsp;экране.
					<br />
					Если гость новый, карточку можно быстро создать.
				</>
			),
			tabletContent: (
				<>
					Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки
					отобразится на&nbsp;экране. Если гость новый, карточку можно быстро
					создать.
				</>
			),
			mobileContent: (
				<>
					Введи имя или телефон&nbsp;&mdash; карточка с&nbsp;адресом доставки
					отобразится на&nbsp;экране.
					<br />
					Если гость новый, карточку можно быстро создать.
				</>
			),
			mediaContent: (
				<div className={styles.mediaContainer}>
					<StaticImage
						className={classNames(styles.mediaImage, styles.mediaImage__third)}
						src={"./assets/yandex-image-3.png"}
						alt={"авторизация гостя на терминале"}
						objectFit="contain"
						breakpoints={[223, 642, 690]}
						quality={90}
					/>
				</div>
			),
			backdropContent: <div className={styles.backdrop}></div>,
		},
	],
}

export default data
